// strings.js

const strings = {
    STRIPE_DEV_PRODUCT_PAYMENT_URL: "https://buy.stripe.com/test_4gw5ln3cVf058yAcNl", // music inpaint 
    STRIPE_PROD_PRODUCT_PAYMENT_URL: "https://buy.stripe.com/7sI14Xa3A1p4ebecNk", // music inpaint

    STRIPE_SUBSCRIPTION_MANAGEMENT_URL: "https://billing.stripe.com/p/login/28ocP200L31J9gs7ss",
    SUBSCRIPTION_STATUS_API_URL: "https://funquiz.app/modular2/subscription-status",
    
    GENERIC_PAGE_TITLE: "Music Inpaint AI 🎨",
    HOME_PAGE_TITLE: "Home - Music Inpaint AI 🎨",
    DASHBOARD_PAGE_TITLE: "Home - Music Inpaint AI 🎨",
    WELCOME_MESSAGE_H1_TITLE: "Welcome to Music Inpaint AI 🎨",
    FX_DESCRIPTION_MESSAGE: "This f(x) can be used to fill out gaps in written music, write your chords, write your notes and then press the 'Submit Input' button below to get your result!",
    
    MAIN_API_NAME: "music_inpaint_ai_1",
    MAIN_API_URL: "https://funquiz.app/modular2/music-inpaint-ai-1",

    PROD_CLERK_PUB_KEY: "pk_live_Y2xlcmsueW9sbGVnLmNvbSQ",
    DEV_CLERK_PUB_KEY: "pk_test_aGFybWxlc3MtYmVlLTY5LmNsZXJrLmFjY291bnRzLmRldiQ",

    // ... add more strings as needed
};



export default strings;